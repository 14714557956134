<template>
  <div>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">Indications pour vous contacter</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')" />
        </header>
        <section class="modal-card-body">
          <div>
            {{ contact }}
          </div>
        </section>
        <footer class="modal-card-foot">
            <b-button
                label="Fermer"
                @click="$emit('close')" />
        </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactModal",
  props: {
    contact: String,
  },
};
</script>

<style lang="css" scoped>
.modal-card-head {
  text-align: center;
  min-width: 500px;
}
.modal-card-foot {
  display: flex;
  justify-content: flex-end;
}
</style>
